
import React from "react";

// reactstrap components

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">

      </footer>
    );
  }
}

export default Footer;
