
import React from "react";

// reactstrap components

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5">

        </footer>
      </>
    );
  }
}

export default Login;
